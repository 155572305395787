@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.dm-serif text-regular {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}

.dm-serif text-regular-italic {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: italic;
}

.mulish-regular {
  font-family: "Mulish", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

#mapbox {
  height: 93vh;
  width: 100%;
}

#mapbox .mapboxgl-popup-content {
  border: 3px solid #dd8e18;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border-radius: 5px;
  text-decoration: none;
  padding: 0;
}

#mapbox .card {
  min-width: 200px;
  max-width: 400px;
}

#mapbox .card-content {
  margin: -4px 10px 15px 10px;
  text-align: center;
}

#mapbox .card-content h1 a {
  font-family: 'DM Serif Text';
  color: black !important;
  text-decoration: none;
  line-height: 1.25em;
}

#mapbox .card-content h1 a:hover {
  text-decoration: underline;
}

#mapbox .card-content-ig p {
  font-family: 'Mulish';
  line-height: 1.35em;
}

#mapbox .card-content-story p {
  font-family: 'Mulish';
  line-height: 1.35em;
  margin-top: -10px;
}

#mapbox .card-content-story :nth-child(4) {
  margin-top: 10px;
}

#mapbox .read-button-link {
  background: #dd8e18;
  color: white;
  font-weight: medium;
  padding: 7px 10px 7px 10px;
  border-radius: 5px;
}

#mapbox .read-button-link:hover {
  background: #aa6d12;
}

#mapbox .ig-button-link {
  background: #e1306c;
  color: white;
  font-weight: medium;
  padding: 7px 10px 7px 10px;
  border-radius: 5px;
}

#mapbox .ig-button-link:hover {
  background: #a8224f;
}

.mapboxgl-popup-close-button {
  color: white;
  font-size: 16px;
}

#ai-word-cloud > p {
  font-family: 'Mulish';
  font-size: 1.2em;
  line-height: 1.35em;
}

#ai-word-cloud > .container {
  border: 2px solid #183058;
}

#ai-word-cloud > form {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 3px;
}

.success-text-group {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 10px;
}

.success-text {
  color: green;
  display: inline-flex;
  gap: 3px;
  align-items: center;
}

.success-svg {
  height: 14px;
  width: 14px;
}

#wordle {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  height: 100%;
  gap: 50px;
}

@media (max-width: 600px) {
  #wordle {
    flex-direction: column;
    gap: 20px;
  }
}

/* #wordle > div:nth-child(2) {
  margin-top: 50px;
} */

#wordle h1 {
  font-family: "DM Serif Text", serif;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
}

#wordle h2, h3 {
  font-family: "DM Serif Text", serif;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
}

#wordle p {
  text-align: center;
}

#wordle table {
  margin-top: 20px;
  border-spacing: 10px;
  margin-left: auto;
  margin-right: auto;
}

#wordle table td {
  padding: 5px;
  width: 42.5px;
  height: 42.5px;
  font-family: "DM Serif Text", serif;
  border: 1px solid #e2e2e2;
  font-size: 1.3em;
  text-align: center;
}

.correct {
  color: green; 
  background: rgb(0 255 0 / 25%);
}

.correct-kb {
  color: green;
}

.wrong-position {
  color: orange;
  background: rgb(255 255 0 / 25%);
}

.wrong-position-kb {
  color: orange;
}

.incorrect {
  background: rgb(0 0 0 / 5%);
}

.incorrect-kb {
  color: rgb(0 0 0 / 25%);
}

.keyboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.keyboard .row {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.keyboard .row button {
  padding: 15px;
  background: whitesmoke;
  appearance: none;
  border: none;
}

@media (max-width: 600px) {
  .keyboard .row button {
    padding: 9px;
  }
}

.keyboard .row button:not(:disabled):hover {
  background: #e2e2e2;
  cursor: pointer;
  outline: none;
}

.keyboard .row button:not(:disabled):active {
  background: gray;
  color: white;
}

.story-link {
  color: black;
  text-decoration: none;
}

.story-link:hover h2 {
  text-decoration: underline;
}

.story-container {
  width: 300px;
}

.story-card {
  background: #f6f6f6;
  padding: 25px;
}

.story-image-cropped {
  height: 175px;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.story-card p {
  font-family: sans-serif;
  font-size: 0.9em;
}